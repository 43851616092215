<template>
  <validation-observer ref="simpleRules">
    <component>

      <b-card v-if="session > 0">

        <!-- Header: Personal Info -->
        <div class="d-flex">
          <feather-icon
            icon="PlusCircleIcon"
            size="19"
          />
          <h4 class="mb-0 ml-50">
            Tambah Mapping User
          </h4>
        </div>

        <!-- Form: Personal Info Form -->
        <b-form class="mt-1">
          <b-row>

            <!-- Field: NIK / Nama -->
            <b-col
              cols="12"
              md="6"
              lg="6"
            >
            <validation-provider
              #default="{ errors }"
              name="NIK/Nama"
              rules="required"
            >
              <b-form-group
                label="NIK/Nama"
                label-for="nama"
                :state="errors.length > 0 ? false:null"
              >
              <v-select
                v-model="userDataInfo.map_user_nik"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                :options="namaOptions"
                :clearable="false"
                input-id="nama"
                placeholder="Pilih NIK/Nama"
              />
              <small class="text-danger">{{ errors[0] }}</small>
              </b-form-group>
            </validation-provider>
            </b-col>
            
            <!-- Field: Bisnis Unit -->
            <!-- <b-col
              cols="12"
              md="6"
              lg="6"
            >
            <validation-provider
              #default="{ errors }"
              name="Bisnis Unit"
              rules="required"
            >
              <b-form-group
                label="Bisnis Unit"
                label-for="bu"
                :state="errors.length > 0 ? false:null"
              >
              <v-select
                v-model="userDataInfo.nama_bu"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                multiple
                :options="buOptions"
                :clearable="false"
                input-id="bu"
                placeholder="Pilih Bisnis Unit"
                @input="changedValue()"
              />
              <small class="text-danger">{{ errors[0] }}</small>
              </b-form-group>
            </validation-provider>
            </b-col> -->

            <!-- Field: Divisi -->
            <!-- <b-col
              cols="12"
              md="6"
              lg="6"
            >
            <validation-provider
              #default="{ errors }"
              name="Divisi"
              rules="required"
            >
              <b-form-group
                label="Divisi"
                label-for="divisi"
                :state="errors.length > 0 ? false:null"
              >
              <v-select
                v-model="userDataInfo.div_desc"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                :options="divOptions[0]"
                :clearable="false"
                input-id="divisi"
                placeholder="Pilih Divisi"
              />
              <small class="text-danger">{{ errors[0] }}</small>
              </b-form-group>
            </validation-provider>
            </b-col> -->

            <!-- <b-col
              cols="12"
              md="6"
              lg="6"
            >
            <validation-provider
              #default="{ errors }"
              name="Segmen"
              rules="required"
            >
              <b-form-group
                label="Segmen"
                label-for="segmen"
                :state="errors.length > 0 ? false:null"
              >
              <v-select
                v-model="userDataInfo.segmen_code"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                :options="segOptions"
                :clearable="false"
                input-id="segmen"
                placeholder="Pilih Segmen"
              />
              <small class="text-danger">{{ errors[0] }}</small>
              </b-form-group>
            </validation-provider>
            </b-col> -->

            <!-- Field: Role -->
            <b-col
              cols="12"
              md="5"
              lg="5"
            >
            <validation-provider
              #default="{ errors }"
              name="Role"
              rules="required"
            >
              <b-form-group
                label="Role"
                label-for="role"
                :state="errors.length > 0 ? false:null"
              >
              <v-select
                v-model="userDataInfo.map_user_role"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                :options="rolOptions"
                :clearable="false"
                input-id="role"
                placeholder="Pilih Role"
              />
              <small class="text-danger">{{ errors[0] }}</small>
              </b-form-group>
            </validation-provider>
            </b-col>

            <!-- Field: Hak Akses -->
            <b-col
              cols="12"
              md="5"
              lg="5"
            >
            <validation-provider
              #default="{ errors }"
              name="Hak Akses"
              rules="required"
            >
              <b-form-group
                label="Hak Akses"
                label-for="hakakses"
                :state="errors.length > 0 ? false:null"
              >
              <v-select
                v-model="userDataInfo.hakakses_name"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                :options="aksesOptions"
                :clearable="false"
                input-id="hakakses"
                placeholder="Pilih Hak Akses"
              />
              <small class="text-danger">{{ errors[0] }}</small>
              </b-form-group>
            </validation-provider>
            </b-col>

            <!-- Field: Aktif -->
            <b-col
              cols="12"
              md="2"
              lg="2"
            >
              <b-form-group
                label="Aktif"
                label-for="aktif"
                label-class="mb-1"
              >
                <b-form-radio-group
                  id="menu_active"
                  v-model="userDataInfo.map_user_status"
                  :options="aktifOptions"
                />
              </b-form-group>
            </b-col>
          </b-row>

          <b-row class="mt-2">
            <b-col>
              <b-button
                variant="primary"
                class="mb-1 mb-sm-0 mr-0 mr-sm-1"
                :block="$store.getters['app/currentBreakPoint'] === 'xs'"
                @click.prevent="validationForm"
              >
                Save Changes
              </b-button>

              <b-button
                variant="outline-secondary"
                :to="{ name: 'apps-mapping-user-list'}"
              >
                Back
              </b-button>
            </b-col>
          </b-row>
        </b-form>
      </b-card>

    </component>
  </validation-observer>
</template>

<script>
import {
  BCard, BRow, BCol, BForm, BFormGroup, BFormInput, BFormRadioGroup, BButton,
} from 'bootstrap-vue'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { required, email } from '@validations'
import vSelect from 'vue-select'
import { ref, onUnmounted, onMounted } from '@vue/composition-api'
import Ripple from 'vue-ripple-directive'
import axios from '@axios'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import {
  codeVueMultiBasic, codeVueMultiIcon, codeMultiPush, codeVueSelectionLimit, codeVueDir,
} from './code'
import { codeVariant } from './codetoast'
import router from '@/router'
import useAppConfig from '@core/app-config/useAppConfig'

export default {
  components: {
    BCard,
    BRow,
    BCol,
    BForm,
    BFormGroup,
    BFormInput,
    vSelect,
    BFormRadioGroup,
    BButton,
    ValidationProvider,
    ValidationObserver,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      emailValue: '',
      name: '',
      required,
      email,
      divOptions: [],
      // segOptions: [],
    }
  },
  methods: {
    async changedValue() {
      const selectedd = JSON.stringify(this.userDataInfo.nama_bu)
      console.log(JSON.stringify(this.userDataInfo.nama_bu))
      const result = await axios.post(`${useAppConfig().AppUrl}global/listdivisibybu`,  { id_bu: selectedd })
      const dataDiv = result.data[0].divisi_by_bu
      // console.log(dataDiv)
      const combo = []
      // eslint-disable-next-line no-plusplus
      for (let i = 0, len = dataDiv.length; i < len; i++) {
        combo.push({ label: dataDiv[i].div_desc, value: dataDiv[i].div_name })
      }
      this.divOptions = []
      this.divOptions.push(combo)

      // const resultSeg = await axios.post(`${useAppConfig().AppUrl}global/listsegmenbybu`,  { id_bu: selectedd })
      // const dataSeg = result.data[0].divisi_by_bu
      // console.log(dataSeg)
      // const comboSeg = []
      // for (let i = 0, len = dataSeg.length; i < len; i++) {
      //   comboSeg.push({ label: dataSeg[i].div_desc, value: dataSeg[i].div_name })
      // }
      // this.segOptions = []
      // this.segOptions.push(comboSeg)
    },
    async validationForm() {
      this.$refs.simpleRules.validate().then(async success => {
        if (success) {
          const params = new URLSearchParams()
          params.append('param', 'add_mapping_user')
          params.append('map_user_pk', JSON.stringify(this.userDataInfo.map_user_nik))
          params.append('div_bu', JSON.stringify(this.userDataInfo.nama_bu))
          params.append('div_name', JSON.stringify(this.userDataInfo.div_desc))
          params.append('segmen_code', JSON.stringify(this.userDataInfo.segmen_code))
          params.append('map_user_role', this.userDataInfo.map_user_role)
          params.append('hakakses_name', JSON.stringify(this.userDataInfo.hakakses_name))
          params.append('map_user_status', this.userDataInfo.map_user_status)
          params.append('created_by', localStorage.getItem('emp_id'))
          const result = await axios.post(`${useAppConfig().AppUrl}mst_mapping_user/add`, params)

          if (result.data.message === 'Berhasil menambah data') {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Notification',
                icon: 'BellIcon',
                text: 'Data Berhasil Disimpan',
                variant: 'success',
              },
            })
            this.$router.push('/apps/mapping-user/list')
          } else {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Notification',
                icon: 'BellIcon',
                text: 'Data Gagal Disimpan',
                variant: 'danger',
              },
            })
            console.log(result)
          }
        }
      })
    },
  },
  setup() {
    const session = ref([])
    onMounted(async () => {
      let char = (router.currentRoute.name).split('-')
      let finChar = ''
      for (let i = 0; i < (char.length)-1; i++) {
        const element = char[i];
        finChar += element+'-'
      }
      console.log(finChar.slice(0, -1));
      let result = await axios.post(`${useAppConfig().AppUrl}global/cekAksesPrevilege`, {emp_id: localStorage.getItem('emp_id'), route_name: finChar.slice(0, -1)})
      session.value= result.data[0].data[0].previlege
    })

    const userDataInfo = ref({
      nama_bu: '',
      map_user_nik: '',
      div_name: '',
      map_user_role: '',
      hakakses_name: '',
      map_user_status: 1,

    })

    const buOptions = ref([])
    const namaOptions = ref([])
    const segOptions = ref([])
    onMounted(async () => {
      const result = await axios.get(`${useAppConfig().AppUrl}global/listCombo`)
      const resultEmp = await axios.get(`${useAppConfig().AppUrl}global/listEmployee`)
      const dataNama = resultEmp.data[0].emp
      const dataBisnisunit = result.data[0].bu
      const dataSegmen = result.data[0].segmen
      const combo = []
      const combo1 = []
      const comboSeg = []
      // eslint-disable-next-line no-plusplus
      for (let i = 0, len = dataNama.length; i < len; i++) {
        combo.push({ label: dataNama[i].id_nama_emp, value: dataNama[i].id })
      }
      for (let i = 0, len = dataBisnisunit.length; i < len; i++) {
        combo1.push({ label: dataBisnisunit[i].nama_bu, value: dataBisnisunit[i].id_bu })
      }
      for (let i = 0, len = dataSegmen.length; i < len; i++) {
        comboSeg.push({ label: dataSegmen[i].segmen_code_name, value: dataSegmen[i].segmen_code })
      }
      namaOptions.value = combo
      buOptions.value = combo1
      segOptions.value = comboSeg
    })

    const aksesOptions = ref([])
    onMounted(async () => {
      const result = await axios.get(`${useAppConfig().AppUrl}global/listCombo`)
      const dataAkses = result.data[0].hakakses
      const combo = []
      // eslint-disable-next-line no-plusplus
      for (let i = 0, len = dataAkses.length; i < len; i++) {
        combo.push({ label: dataAkses[i].hakakses_desc, value: dataAkses[i].hakakses_name })
      }
      aksesOptions.value = combo
    })


    const rolOptions = [
      'Admin',
      'Sales',
      'Approval Sales',
      // 'Approval Divisi',
      'View Sales',
      'View All'
    ]

    const aktifOptions = [
      { text: 'Ya', value: 1 },
      { text: 'Tidak', value: 0 },
    ]

    return {
      session,
      codeVueMultiBasic,
      codeVueMultiIcon,
      codeMultiPush,
      codeVueSelectionLimit,
      codeVueDir,
      aktifOptions,
      userDataInfo,
      buOptions,
      namaOptions,
      rolOptions,
      aksesOptions,
      codeVariant,
      segOptions,
    }
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-flatpicker.scss';
</style>

<style>

</style>
